import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: "/",
      // name: "index",
      component: () => import("../views/home/homePage"),
      children: [
        { // 首页
          path: '/',
          name: "principalSheet",
          component: () => import("../views/principal-sheet/principalSheet"),
        },
        { // 联系我们
          path: '/contact-us',
          name: 'contactUs',
          component: () => import("../views/contact-us/contactUs"),
        },
        { // 小程序
          path: '/applet',
          name: 'applet',
          component: () => import("../views/applet/index"),
        }
      ]
    },
    /**
     * 小程序数据监控
     */
    { // 数据概览
      path: '/monitors',
      name: 'monitors',
      component: () => import("../views/monitor/overview"),
    },
    { // 世界地图
      path: '/world',
      name: 'world',
      component: () => import("../views/monitor/world"),
    },
    { // 漏洞扫描任务
      path: '/vs-task',
      name: 'vsTask',
      component: () => import("../views/monitor/vs-task"),
    },
    { // 漏洞扫描新建任务
      path: '/vs-task/create',
      name: 'vsTaskCreate',
      component: () => import("../views/monitor/vs-task/create"),
    },
  ],
  mode: 'history'
})
